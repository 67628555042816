import React from 'react';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const AutomatedFields = ({
  campaignColumns,
  currentTab,
  isRefMarkerEditable,
  generateRemoraRefMarker,
}) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  const getGenerateRefMarkerButton = () => {
    if (!isRefMarkerEditable) {
      return null;
    }

    return (
      <Button type="primary" size="small" onClick={generateRemoraRefMarker}>
        Generate/Re-generate Ref Marker
      </Button>
    );
  };

  const getTrafficAutomatedFields = () => {
    return (
      <div className={styles.formGroupContent}>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField
              {...campaignField(campaignColumns.CAMPAIGN_NAME)}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...campaignField(campaignColumns.REF_MARKER)}
              isDisabled={!isRefMarkerEditable}
            />
          </div>
          <div className={styles.formControl}>
            {getGenerateRefMarkerButton()}
          </div>
        </div>
      </div>
    );
  };

  const trafficAutomatedFields = getTrafficAutomatedFields();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Automated Traffic fields</div>
      <div className={styles.formGroupWrapper}>
        {trafficAutomatedFields}
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              Automated fields
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomatedFields;
