import { RemoraRefMarkerActions } from 'src/constants';

export const generateRemoraRefMarker = (packageId, campaignId, isRequestFromFormView = false) => ({
  type: RemoraRefMarkerActions.GENERATE_REF_MARKER,
  payload: {
    packageId,
    campaignId,
    isRequestFromFormView,
  },
});
