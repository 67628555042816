import { CampaignColumns } from '.';

export const ImageColumns = [
  CampaignColumns.DESKTOP_IMAGE_1x.name,
  CampaignColumns.DESKTOP_IMAGE_2x.name,
  CampaignColumns.MOBILE_IMAGE_1x.name,
  CampaignColumns.MOBILE_IMAGE_2x.name,
  CampaignColumns.TABLET_IMAGE_1x.name,
  CampaignColumns.TABLET_IMAGE_2x.name,
];
