import { connect } from 'react-redux';
import { onOpenToast } from 'src/actionCreators';
import { generateRemoraRefMarker } from 'src/actionCreators/remoraRefMarker';
import { getFirstOnlineCampaign } from 'src/helpers/campaign';
import { Language } from 'src/constants';
import AutomatedFields from './AutomatedFields';
import { getCurrentPackageActiveTab } from '../../../../../helpers/form';

export const mapStateToProps = (state) => {
  return {
    currentTab: getCurrentPackageActiveTab(state),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    generateRemoraRefMarker() {
      const { pkg: { campaigns } = {} } = ownProps;
      const firstOnlineCampaign = getFirstOnlineCampaign(campaigns);
      if (!firstOnlineCampaign) {
        dispatch(onOpenToast(Language.REMORA_REF_MARKER_ERROR_NOT_SUPPORTED));
        return;
      }
      const { packageId, id } = firstOnlineCampaign;

      if (window.confirm(Language.REMORA_REF_MARKER_CONFIRM_GENERATION)) {
        dispatch(generateRemoraRefMarker(packageId, id, true));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedFields);
