var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { change } from 'redux-form';
import { CampaignColumns, Form, RemoraRefMarkerActions } from 'src/constants';
import callRemora from 'src/remora/callRemora';
import { getPackage } from 'src/selectors/package';
import { onEditCampaign, onEditPackage, onOpenToast } from 'src/actionCreators';
import { isWebsiteOmnichannelViewMode } from 'src/selectors/sitewide';
import { createCampaignFieldForIndex } from 'src/helpers/form';
export default (function (_a) {
    var getState = _a.getState, dispatch = _a.dispatch;
    return function (next) { return function (action) {
        var type = action.type, payload = action.payload;
        if (type === RemoraRefMarkerActions.GENERATE_REF_MARKER) {
            var state_1 = getState();
            var packageId = payload.packageId, campaignId_1 = payload.campaignId, isRequestFromFormView_1 = payload.isRequestFromFormView;
            // @ts-ignore
            var pkg_1 = getPackage(state_1, packageId);
            var campaign_1 = pkg_1.campaigns.find(function (currentCampaign) { return currentCampaign.id === campaignId_1; });
            callRemora(pkg_1, campaign_1)
                .then(function (response) { return response.text(); })
                .then(function (remoraRefMarker) {
                var _a, _b;
                if (!isWebsiteOmnichannelViewMode(state_1) && isRequestFromFormView_1) {
                    var campaignRefMarkerField = createCampaignFieldForIndex(0)(CampaignColumns.REF_MARKER).name;
                    dispatch(change(Form.NAME, campaignRefMarkerField, remoraRefMarker));
                    dispatch(change(Form.NAME, CampaignColumns.REF_MARKER.name, remoraRefMarker));
                }
                else {
                    dispatch(onEditCampaign(__assign(__assign({}, campaign_1), (_a = {}, _a[CampaignColumns.REF_MARKER.name] = remoraRefMarker, _a))));
                    dispatch(onEditPackage(__assign(__assign({}, pkg_1), (_b = {}, _b[CampaignColumns.REF_MARKER.name] = remoraRefMarker, _b)), { skipCampaignsUpdate: true }));
                }
                dispatch(onOpenToast('Remora Ref Marker has been successfully created!'));
            })
                .catch(function (error) {
                var errorMessage = error && error.message
                    ? error.message
                    : '';
                dispatch(onOpenToast(errorMessage));
            });
        }
        return next(action);
    }; };
});
