export var Channel = Object.freeze({
    ONSIT: 'ONSIT',
});
export var Marketplace = Object.freeze({
    DE: 'DE',
    ES: 'ES',
    IT: 'IT',
    JP: 'JP',
    SG: 'SG',
    UK: 'UK',
    US: 'US',
});
export var CampaignPlatform = Object.freeze({
    CONTENT_SYMPHONY: 'Content Symphony',
});
export var BusinessFunction = Object.freeze({
    TRF: 'TRF',
});
export var Platform = Object.freeze({
    ALL: 'ALL',
    DSK: 'DSK',
    MOB: 'MOB',
    TAB: 'TAB',
});
export var Business = Object.freeze({
    NW3: 'NW3',
    OTH: 'OTH',
    RTL: 'RTL',
    WFM: 'WFM',
});
export var Program = Object.freeze({
    FSH: 'FSH',
    MNL: 'MNL',
    OMNI_CHANNEL: 'Omni-channel',
    ONLINE: 'Online',
    STORES: 'Stores',
    UFG: 'UFG',
});
export var CustomerSegment = Object.freeze({
    ACQUISITION: 'Acquisition',
    ACQ_ENG: 'Acq & Eng',
    ALL_ELIGIBLE_CUSTOMERS: 'All Eligible Customers',
    ENGAGEMENT: 'Engagement',
    OTHER_NA: 'Other-N/A',
});
export var CampaignType = Object.freeze({
    EVENT: 'Event',
    EVERGREEN: 'Evergreen',
    STORE_LAUNCH: 'Store Launch',
});
export var PlacementType = Object.freeze({
    AD_TOPPER: 'Ad Topper',
    ATF_DESKTOP_QCC: 'ATF Desktop QCC',
    ATF_QUAD_CATEGORY_CARD: 'ATF Quad Category Card',
    ATF_SCC: 'ATF SCC',
    ATF_SINGLE_CATEGORY_CARD: 'ATF Single Category Card',
    BILLBOARD: 'Billboard',
    BTF_DESKTOP_QCC: 'BTF Desktop QCC',
    BTF_QUAD_CATEGORY_CARD: 'BTF Quad Category Card',
    BTF_SCC: 'BTF SCC',
    BTF_SINGLE_CATEGORY_CARD: 'BTF Single Category Card',
    HERO: 'Hero',
    HERO_QCC: 'Hero QCC',
    HQP: 'HQP',
    ILM: 'ILM',
    MOBILE_BILLBOARD: 'Mobile Billboard',
    MOBILE_DASHBOARD_CARD: 'Mobile Dashboard Card',
    MOBILE_QCC: 'Mobile QCC',
    MOBILE_QUAD_CATEGORY_CARD: 'Mobile Quad Category Card',
    MOBILE_SCC: 'Mobile SCC',
    MOBILE_SINGLE_CATEGORY_CARD: 'Mobile Single Category Card',
    OTH: 'OTH',
    SIGNPOST: 'Signpost',
    SPARKLE: 'Sparkle',
    STRIPE: 'Stripe',
});
