import { createOrUpdateCsCampaignById } from 'src/graphql/mutations';
import { validateCsCampaignInput } from 'src/graphql/queries';
import {
  onCreateCsCampaignCalcDiff,
  onCreateCsCampaignClearValidationErrors,
  onCreateCsCampaignCloseModal,
  onCreateCsCampaignSetRunningStatus,
  onCreateCsCampaignSetValidationErrors,
  onOpenToast,
  onSendCampaignEdit,
  onUpdateCampaignColumn,
} from 'src/actionCreators';
import onGraphQLAction from 'src/actionCreators/graphql';
import { submitAppSyncError, submitStringMetric } from 'src/metrics';
import {
  CampaignColumns,
  GraphQLLabels,
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from 'src/constants';

export default (state, dispatch) => {
  const {
    User: { currentUser: { username } },
    ContentSymphony: {
      campaign,
      creativeData,
      placementData,
      contentData,
      csCampaignIdsData,
      shouldValidateCsCampaignInput,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;
  const { id, business_startYear, packageId } = campaign;

  const creativeInput = creativeData;

  const getMetricsContext = (input = {}) => {
    return {
      location: MetricLocations.CREATE_SYMPHONY_CAMPAIGN,
      type: MetricTypes.APPSYNC,
      data: input,
      action: MetricNames.CREATE_SYMPHONY_CAMPAIGN,
      username,
    };
  };

  const getInputQuery = (isValidationQuery = false) => {
    return {
      input: {
        creative: creativeInput,
        placement: placementData,
        content: contentData,
        csCampaignIds: !isValidationQuery ? csCampaignIdsData : {},
        business: selectedBusiness,
      },
    };
  };
  const getValidationInputQuery = () => getInputQuery(true);

  const dispatchCreateOrUpdateCsCampaignById = () => {
    const input = {
      campaignId: id,
      business_startYear: business_startYear,
      accessControlGroupId: creativeData.accessControlGroupId,
      businessGroup: creativeData.businessGroup,
      description: creativeData.description,
      intent: creativeData.intent,
    };
    submitStringMetric(MetricNames.CREATE_SYMPHONY_CAMPAIGN, getMetricsContext(input));
    dispatch(onGraphQLAction({
      graphqlAction: createOrUpdateCsCampaignById,
      data: input,
      label: GraphQLLabels.CREATE_OR_UPDATE_CS_CAMPAIGN,
      onSuccessFunction(response) {
        // eslint-disable-next-line no-shadow
        const { data: { createOrUpdateCsCampaignById } } = response;
        const {
          updatedCampaign: {
            creative: {
              creativeUrl,
            },
          },
          existingCampaign,
          csCampaignIds,
        } = createOrUpdateCsCampaignById;
        const successMessage = existingCampaign
          ? Language.CREATIVE_UPDATE_SUCCESS
          : Language.CREATIVE_CREATION_SUCCESS;

        dispatch(onUpdateCampaignColumn(
          campaign,
          CampaignColumns.SYMPHONY_URL.name,
          creativeUrl,
        ));

        dispatch(onUpdateCampaignColumn(
          campaign,
          CampaignColumns.CS_CAMPAIGN_IDS.name,
          csCampaignIds,
        ));

        dispatch(onSendCampaignEdit(id, packageId));
        dispatch(onCreateCsCampaignSetRunningStatus(false));
        dispatch(onOpenToast(successMessage));
        dispatch(onCreateCsCampaignClearValidationErrors());
        dispatch(onCreateCsCampaignCloseModal());
        dispatch(onCreateCsCampaignCalcDiff(createOrUpdateCsCampaignById));
      },
      onFailureFunction(error) {
        dispatch(onCreateCsCampaignSetRunningStatus(false));
        dispatch(onOpenToast(Language.CREATIVE_CREATION_ERROR));
        submitAppSyncError(error, getMetricsContext(input));
      },
    }));
  };

  dispatch(onCreateCsCampaignSetRunningStatus(true));
  if (shouldValidateCsCampaignInput) {
    const input = getValidationInputQuery();

    submitStringMetric(
      MetricNames.CREATE_SYMPHONY_CAMPAIGN,
      getMetricsContext(input),
    );

    dispatch(onGraphQLAction({
      graphqlAction: validateCsCampaignInput,
      data: input,
      label: GraphQLLabels.VALIDATE_CS_CAMPAIGN_INPUT,
      onSuccessFunction(data) {
        const {
          data: {
            validateCsCampaignInput: {
              creative,
              desktopPlacement,
              mobilePlacement,
              content,
            },
          },
        } = data;

        const { errorMessage: creativeErrorMessage } = creative || {};
        const { errorMessage: desktopPlacementErrorMessage } = desktopPlacement || {};
        const { errorMessage: mobilePlacementErrorMessage } = mobilePlacement || {};
        const { errorMessage: contentErrorMessage } = content || {};

        if (creativeErrorMessage == null
            && desktopPlacementErrorMessage == null
            && mobilePlacementErrorMessage == null
            && contentErrorMessage == null) {
          dispatchCreateOrUpdateCsCampaignById();
        } else {
          dispatch(onCreateCsCampaignSetValidationErrors({
            creativeErrorMessage,
            desktopPlacementErrorMessage,
            mobilePlacementErrorMessage,
            contentErrorMessage,
          }));
          dispatch(onCreateCsCampaignSetRunningStatus(false));
        }
      },
      onFailureFunction(error) {
        dispatch(onCreateCsCampaignSetRunningStatus(false));
        dispatch(onOpenToast(Language.CREATIVE_CREATION_ERROR));
        submitAppSyncError(error, getMetricsContext(input));
      },
    }));
  } else {
    dispatchCreateOrUpdateCsCampaignById();
  }
};
