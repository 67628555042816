import { getDeployedLocation } from 'src/helpers/env';
import { DeployLocations } from 'src/constants';
var BETA = {
    API_URL: 'https://sdhl2wchg1.execute-api.us-east-1.amazonaws.com/beta',
    COGNITO_POOL_ID: 'us-east-1:2d94750a-5d06-40e6-9eca-fc562b49734a',
    REGION: 'us-east-1',
};
var PROD = {
    API_URL: 'https://18cit59db5.execute-api.us-east-1.amazonaws.com/prod',
    COGNITO_POOL_ID: 'us-east-1:d0095e1a-1878-43aa-97ad-d45bb78e32ec',
    REGION: 'us-east-1',
};
export var config = function () {
    switch (getDeployedLocation()) {
        case DeployLocations.PROD:
            return PROD;
        default:
            return BETA;
    }
};
export default config();
