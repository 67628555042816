var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AwsClient } from 'aws4fetch';
import { getItemBusiness, isDEBusiness, isESBusiness, isFreshBusiness, isFreshOnsiteTraffic, isFreshSGBusiness, isGeneric3PBusiness, isITBusiness, isJPBusiness, isUKBusiness, isWFMBusiness, isWFMCBusiness, } from 'src/helpers/common';
import { DeviceTypes } from 'src/constants';
import { Business, BusinessFunction, CampaignPlatform, CampaignType, Channel, CustomerSegment, Marketplace, PlacementType, Platform, Program, } from 'src/constants/remora';
import config from './config';
import { getCredentials } from './credentials';
var uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/g;
var identifyMarketplace = function (business) {
    if (isUKBusiness(business)) {
        return Marketplace.UK;
    }
    if (isITBusiness(business)) {
        return Marketplace.IT;
    }
    if (isDEBusiness(business)) {
        return Marketplace.DE;
    }
    if (isESBusiness(business)) {
        return Marketplace.ES;
    }
    if (isJPBusiness(business)) {
        return Marketplace.JP;
    }
    if (isFreshSGBusiness(business)) {
        return Marketplace.SG;
    }
    return Marketplace.US;
};
var identifyPlatform = function (device) {
    if (DeviceTypes.DESKTOP === device) {
        return Platform.DSK;
    }
    if (DeviceTypes.MOBILE === device) {
        return Platform.MOB;
    }
    if (DeviceTypes.TABLET === device) {
        return Platform.TAB;
    }
    if (DeviceTypes.ALL === device) {
        return Platform.ALL;
    }
    throw new Error('Unexpected device');
};
var identifyBusiness = function (business) {
    if (isGeneric3PBusiness(business)) {
        return Business.NW3;
    }
    if (isWFMBusiness(business) || isWFMCBusiness(business)) {
        return Business.WFM;
    }
    if (isFreshBusiness(business) || isFreshOnsiteTraffic(business)) {
        return Business.RTL;
    }
    throw new Error('Unexpected business');
};
var identifyProgram = function (program) {
    if (program === Program.ONLINE) {
        return Program.UFG;
    }
    if (program === Program.STORES) {
        return Program.MNL;
    }
    if (program === Program.OMNI_CHANNEL) {
        return Program.FSH;
    }
    throw new Error('Unexpected program');
};
var identifyCustomerSegment = function (targetingType) {
    if (targetingType.includes(CustomerSegment.ACQUISITION)) {
        return CustomerSegment.ACQUISITION;
    }
    if (targetingType.includes(CustomerSegment.ENGAGEMENT)) {
        return CustomerSegment.ENGAGEMENT;
    }
    if (targetingType === CustomerSegment.ALL_ELIGIBLE_CUSTOMERS) {
        return CustomerSegment.ACQ_ENG;
    }
    return CustomerSegment.OTHER_NA;
};
var identifyCampaignType = function (initiative) {
    if (initiative === CampaignType.EVENT || initiative === CampaignType.STORE_LAUNCH) {
        return CampaignType.EVENT;
    }
    return CampaignType.EVERGREEN;
};
var identifyPlacementType = function (placement) {
    if (placement === PlacementType.AD_TOPPER) {
        return PlacementType.AD_TOPPER;
    }
    if (placement === PlacementType.STRIPE) {
        return PlacementType.STRIPE;
    }
    if (placement === PlacementType.ATF_SCC) {
        return PlacementType.ATF_SINGLE_CATEGORY_CARD;
    }
    if (placement === PlacementType.BTF_SCC) {
        return PlacementType.BTF_SINGLE_CATEGORY_CARD;
    }
    if (placement === PlacementType.MOBILE_DASHBOARD_CARD) {
        return PlacementType.MOBILE_DASHBOARD_CARD;
    }
    if (placement === PlacementType.MOBILE_BILLBOARD) {
        return PlacementType.BILLBOARD;
    }
    if (placement === PlacementType.MOBILE_SCC) {
        return PlacementType.MOBILE_SINGLE_CATEGORY_CARD;
    }
    if (placement === PlacementType.HERO_QCC || placement === PlacementType.HERO) {
        return PlacementType.HERO;
    }
    if (placement === PlacementType.ILM) {
        return PlacementType.ILM;
    }
    if (placement === PlacementType.HQP) {
        return PlacementType.HQP;
    }
    if (placement === PlacementType.MOBILE_QCC) {
        return PlacementType.MOBILE_QUAD_CATEGORY_CARD;
    }
    if (placement === PlacementType.BTF_DESKTOP_QCC) {
        return PlacementType.BTF_QUAD_CATEGORY_CARD;
    }
    if (placement === PlacementType.ATF_DESKTOP_QCC) {
        return PlacementType.ATF_QUAD_CATEGORY_CARD;
    }
    if (placement === PlacementType.SPARKLE) {
        return PlacementType.SPARKLE;
    }
    if (placement === PlacementType.SIGNPOST) {
        return PlacementType.SIGNPOST;
    }
    return PlacementType.OTH;
};
export var buildRemoraInput = function (pkg, campaign) {
    var missingAttribute = [];
    if (!pkg) {
        missingAttribute.push('pkg');
    }
    else if (!pkg.program) {
        missingAttribute.push('pkg.program');
    }
    if (!campaign) {
        missingAttribute.push('campaign');
    }
    else {
        if (!campaign.business_startYear) {
            missingAttribute.push('campaign.business_startYear');
        }
        if (!campaign.scheduler) {
            missingAttribute.push('campaign.scheduler');
        }
        if (!campaign.device) {
            missingAttribute.push('campaign.device');
        }
        if (!campaign.targetingType) {
            missingAttribute.push('campaign.targetingType');
        }
        if (!campaign.initiative) {
            missingAttribute.push('campaign.initiative');
        }
        if (!campaign.placement) {
            missingAttribute.push('campaign.placement');
        }
        if (!campaign.symphonyUrl) {
            missingAttribute.push('campaign.symphonyUrl');
        }
        else if (!campaign.symphonyUrl.match(uuidRegex)) {
            missingAttribute.push('a valid symphonyUrl (url does not have a valid ID that conforms to UUID format)');
        }
    }
    if (missingAttribute.length > 0) {
        throw new Error("Cannot create remora input without ".concat(missingAttribute));
    }
    var business = getItemBusiness(campaign);
    return {
        creatorAlias: campaign.scheduler,
        marketplace: identifyMarketplace(business),
        businessFunction: BusinessFunction.TRF,
        platform: identifyPlatform(campaign.device),
        program: identifyProgram(pkg.program),
        business: identifyBusiness(business),
        channel: Channel.ONSIT,
        vertical: '',
        customerSegment: identifyCustomerSegment(campaign.targetingType),
        campaignType: identifyCampaignType(campaign.initiative),
        campaignPlatform: CampaignPlatform.CONTENT_SYMPHONY,
        placementType: identifyPlacementType(campaign.placement),
        pageType: '',
        widget: '',
        lifeCycleChecked: false,
        promotionChecked: false,
        eventChecked: false,
        vfccChecked: false,
        programName: pkg.program.substring(66),
        campaignId: campaign.symphonyUrl.match(uuidRegex)[0],
        emailProgramId: '',
        emailMatchType: '',
        controlSegment: '',
        treatmentSegment: '',
        promotionId: '',
        comment: 'Created By Marketing Calendar',
        refMarkerOverride: '',
    };
};
var aws = new AwsClient({
    accessKeyId: '123',
    secretAccessKey: 'abc',
    service: 'execute-api',
    region: config.REGION, // AWS region, by default parsed at fetch time
});
function sign(originalRequest, credentials) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, aws.sign(originalRequest.apiUrl, {
                    method: originalRequest.method || 'GET',
                    headers: originalRequest.headers,
                    body: originalRequest.body,
                    aws: {
                        accessKeyId: credentials.accessKeyId,
                        secretAccessKey: credentials.secretAccessKey,
                        sessionToken: credentials.sessionToken,
                    },
                })];
        });
    });
}
export function callRemora(pkg, campaign) {
    return __awaiter(this, void 0, void 0, function () {
        var credentials, request, signedRequest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCredentials(config.COGNITO_POOL_ID, config.REGION)];
                case 1:
                    credentials = _a.sent();
                    request = {
                        apiUrl: new URL(config.API_URL),
                        body: JSON.stringify(buildRemoraInput(pkg, campaign)),
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                        },
                    };
                    return [4 /*yield*/, sign(request, credentials)];
                case 2:
                    signedRequest = _a.sent();
                    return [2 /*return*/, fetch(signedRequest)];
            }
        });
    });
}
export default callRemora;
